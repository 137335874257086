import { Fragment, useEffect, useState } from "react";
import { Image, Row, Card } from "react-bootstrap";
import charne from "assets/images/team/charne.jpg";
import david from "assets/images/team/david.jpg";
import jesse from "assets/images/team/jesse.jpg";
import lc from "assets/images/team/lc.jpg";
import alex from "assets/images/team/alex.jpg";
import TeamSocials from "common/socials/TeamSocials";
import networkedWorld from "../../assets/images/background/networked-world.mp4";
import dna from "../../assets/images/background/dna.mp4";

const About = () => {
  const [ios, setIos] = useState(true);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={networkedWorld} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
              backgroundColor: "#00000079",
              paddingTop: "150px",
              paddingBottom: "100px",
            }}>
            <h1 className="fw-bold display-2 text-center mb-5 text-white">
              Our <span className="text-primary">Mission</span>
            </h1>
            <div className="mx-auto fs-3 hero-lead text-white">
              <p>
                Empowering the masses through simplicity and accessibility, our
                vision is to lead the charge in making distributed ledger
                technology a ubiquitous and user-friendly tool for positive
                global impact. We see a future where individuals from all walks
                of life can seamlessly engage with blockchain solutions,
                unlocking new possibilities for collaboration, innovation, and
                financial inclusion.
              </p>
              <p>
                At Decentralized Ventures, our mission is to create intuitive
                and user-friendly experiences that break down barriers and make
                the benefits of decentralization accessible to individuals
                worldwide. Through innovative UI/UX solutions, educational
                initiatives, and collaborative partnerships, we strive to
                empower users to navigate the decentralized landscape
                confidently. Our commitment to user privacy and security forms
                the foundation of our mission, ensuring that every interaction
                with decentralized technology is a step towards a more inclusive
                and equitable digital future.
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={dna} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
              paddingTop: "150px",
              paddingBottom: "150px",
            }}
            className="moving-gradient-bg">
            <h2 className="fw-bold display-3 text-center mb-5 text-white">
              Meet the <span className="text-primary">Team</span>
            </h2>
            <Row className="d-flex justify-content-center mt-13 mx-5 gap-5">
              <Card className="shadow-lg mb-10" style={{ width: "400px" }}>
                <Card.Header>
                  <div className="position-relative d-flex justify-content-end align-items-end mt-n10">
                    <Image
                      className="avatar-xxl mx-auto rounded-circle border border-4 border-white position-relative shadow-lg"
                      src={jesse}
                    />
                  </div>
                  <div className="text-center fw-bold fs-3 mt-3">
                    <p className="mb-0">Jesse Wachtel</p>
                    <p className="mb-1 lh-1">Founder</p>
                    <p className="mb-0 fs-5">
                      Powhatan, Virginia, United States
                    </p>
                  </div>
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">
                    As a passionate full-stack Web 3 developer and the driving
                    force behind Decentralized Ventures, Jesse is dedicated to
                    crafting user-friendly applications that embody the
                    principles of decentralization. Specializing in creating
                    seamless integration of smart contracts on decentralized
                    networks, he is committed to revolutionizing how everyday
                    tasks are completed on the internet. Jesse's focus extends
                    to being at the forefront of the Web 3 movement, where he
                    contributes to the transformation of digital experiences by
                    prioritizing security, privacy, and fostering an egalitarian
                    internet globally.
                  </p>
                </Card.Body>
                <Card.Footer>
                  <TeamSocials
                    linkedin={"https://www.linkedin.com/in/jesse-wachtel/"}
                    twitter={"https://twitter.com/bchaintrucker"}
                  />
                </Card.Footer>
              </Card>
              <Card className="shadow-lg mb-10" style={{ width: "400px" }}>
                <Card.Header>
                  <div className="position-relative d-flex justify-content-end align-items-end mt-n10">
                    <Image
                      className="avatar-xxl mx-auto rounded-circle border border-4 border-white position-relative shadow-lg"
                      src={alex}
                    />
                  </div>
                  <div className="text-center fw-bold fs-3 mt-3">
                    <p className="mb-0">Alexandra Overgaag</p>
                    <p className="mb-1 lh-1">Business Advisor</p>
                    <p className="mb-0 fs-5">Florence, Tuscany, Italy</p>
                  </div>
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">
                    Alex is the Founder and CEO of Thrilld Labs, a Web3 platform
                    designed to optimize business interactions and foster
                    equitable opportunities. She has a strong background in
                    blockchain technology and digital governance, and holds an
                    Executive Master’s from Luiss Guido Carli University and a
                    Master of Science from Università degli Studi di Firenze.
                    She is a published author in Cointelegraph and IGI Global,
                    and mentors emerging talents at the Frankfurt School
                    Blockchain Center. Through Thrilld Labs and her community
                    for female Web3 professionals, EcstaSHE, Alex continues to
                    drive innovation and inclusivity in the blockchain space.
                  </p>
                </Card.Body>

                <Card.Footer>
                  <TeamSocials
                    linkedin={"https://www.linkedin.com/in/alexandra-overgaag/"}
                  />
                </Card.Footer>
              </Card>

              <Card className="shadow-lg mb-10" style={{ width: "400px" }}>
                <Card.Header>
                  <div className="position-relative d-flex justify-content-end align-items-end mt-n10">
                    <Image
                      className="avatar-xxl mx-auto rounded-circle border border-4 border-white position-relative shadow-lg"
                      src={charne}
                    />
                  </div>
                  <div className="text-center fw-bold fs-3 mt-3">
                    <p className="mb-0">Charné Thompson</p>
                    <p className="mb-1 lh-1">Legal Advisor</p>
                    <p className="mb-0 fs-5">
                      City of Cape Town, Western Cape, South Africa
                    </p>
                  </div>
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">
                    Charné is a dynamic Web 3 Attorney specializing in
                    delivering tailored legal and advisory solutions to global
                    and local companies in the Web3, IT, and Tech startup
                    sectors. Charné has a profound understanding of laws related
                    to blockchain technology, tokenization, corporate
                    structuring, data protection, global Anti-Money Laundering,
                    and regulatory frameworks. As the Director and Founder of
                    Thompsons Legal Alliance, and as a Director and Co-Founder
                    at CarbonTokenFund and SAME.Ltd, Charné is deeply involved
                    in the industry. Furthermore, she serves as the head of the
                    African Chapter for Women in Web3, contributing to community
                    growth and innovation in the sector.
                  </p>
                </Card.Body>
                <Card.Footer>
                  <TeamSocials
                    linkedin={"https://www.linkedin.com/in/web3lawyer/"}
                    twitter={"https://twitter.com/the_web3lawyer"}
                  />
                </Card.Footer>
              </Card>
              <Card className="shadow-lg mb-10" style={{ width: "400px" }}>
                <Card.Header>
                  <div className="position-relative d-flex justify-content-end align-items-end mt-n10">
                    <Image
                      className="avatar-xxl mx-auto rounded-circle border border-4 border-white position-relative shadow-lg"
                      src={lc}
                    />
                  </div>
                  <div className="text-center fw-bold fs-3 mt-3">
                    <p className="mb-0">LC Stroud</p>
                    <p className="mb-1 lh-1">Marketing Advisor</p>
                    <p className="mb-0 fs-5">London, England, United Kingdom</p>
                  </div>
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">
                    LC is a highly adaptable marketer with a wealth of skills in
                    digital marketing. Her proficiency spans community
                    management, strategic planning, social media management, SEO
                    optimization, copywriting, branding, and effective
                    communication strategies. LC's successful Web 2 career is a
                    testament to her expertise in traditional marketing
                    strategies, coupled with a proactive approach to staying
                    ahead of industry trends. In recent years, she has devoted
                    herself to becoming an authority in Web 3, broadening her
                    skills extensively within the decentralized ledger
                    technology sphere. LC's unique skill set and
                    forward-thinking approach make her an invaluable resource
                    for driving innovative and effective marketing strategies in
                    the dynamic Web 3 space.
                  </p>
                </Card.Body>

                <Card.Footer>
                  <TeamSocials
                    linkedin={
                      "https://www.linkedin.com/in/david-steenhoek-a681a69/"
                    }
                    twitter={"https://twitter.com/DavidSteenhoek"}
                  />
                </Card.Footer>
              </Card>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
